import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./plugins/element.js";
import ajax from "./plugins/ajax";
import util from "./plugins/utils";
import bus from 'vue-bus';
import $ from 'jquery'
import store from './store/index'

import './assets/icon-font/iconfont.css'
// 引入echarts图表工具
import echarts from 'echarts'
console.log(echarts)
Vue.prototype.$echarts = echarts

Vue.prototype.$ajax = ajax
Vue.prototype.$utils = util
Vue.prototype.$ = $

Vue.use(bus)

/*开发环境设置为false*/
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
