export default {
    // 添加tabs
    add_tabs (state, data) {
        state.editableTabs.push(data);
    },
    // 刷新重置tabs
    refresh_tabs (state, data) {
        state.editableTabs=data
    },
    // 删除tabs
    delete_tabs (state, route) {
        let index = 0;
        for (let option of state.editableTabs) {
            if (option.name == route) {
                break;
            }
            index++;
        }
        state.editableTabs.splice(index, 1);
    },
    // 设置当前激活的tab
    set_active_index (state, index) {
        state.editableTabsValue = index;
    },
    // 是否是要新开tab页
    changeTab(state,newTabBool){
        state.isTab=newTabBool
    },
    // 还原tab数组
    clearTabs(state){
        state.editableTabs=[]
        state.editableTabsValue="我的记录",
        state.editableTabs= [
            {content: 'myrecord' , name: '我的记录'}
        ],
        state.isTab= true
    }, 
    setDefaultTab(state,item){
        state.editableTabs=[]
        state.editableTabsValue=item.name,
        state.editableTabs= [
            {content: item.content , name: item.name}
        ],
        state.isTab= true
    },
    // 设置识别详情页面传参数据
    setCompaireParam(state,param){
        state.compaireParam=param
    },
    setPageParam(state,param){
        state.pageParam=param
    },

    // 置空二维码扫描的结果code和state
    clearLoginParam(state){
        state.loginParam={}
    },

    setQueryKey(state,val){
        state.queryWord=val
    }
}