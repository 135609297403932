import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
console.log(store)
Vue.use(VueRouter)

let routes= [
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '/login',
      name: 'login',
      component: () =>import("../views/login/index"),
      meta:{
        name: '登录页面'
      }
    },
    {
      path: '/index',
      name: 'index',
      component: () =>import("../views/index"),
      meta:{
        name: 'index页'
      }
    }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
router.beforeEach((to,from,next)=>{
  const wx_url =window.location.href;  

  if(wx_url.indexOf('code')>=0){
    //如果url中包含code,则保存到store中
    var paramArr = wx_url.split("?")[1].split("&")
    paramArr.forEach(item=>{
      var [key,val]=item.split("=")
      store.state.loginParam[key]=val
    })
    console.log(store.state.loginParam)
  }
  console.log(to.name,sessionStorage.getItem("token"))
  if(to.name=="login") {
    next()
  }else if(to.name!='login'&&!store.state.loginParam.code&&!sessionStorage.getItem("token")){
    next("/login")
  }else{
    console.log(to.name)
    // store.state.beforeLoginUrl = to.fullPath; // 保存用户进入的url
    next()
  }
})

export default router;