<template>
  <div id="app">
      <router-view style="width: 100%;heihgt: 100%;" ref="indexview"></router-view>
  </div>
</template>
<script>
export default {
  name: "app",
};
</script>
<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}
body .el-table th.gutter{
    display: table-cell!important;
}
.el-table td{
  font-size: 13px;
}
.el-table__row .el-table-column--selection .cell{
  padding: 0;
  text-align: center;
}
</style>
